import { Button, CircularProgress } from "@mui/material";
import { CurrentUserAvatar, Modal } from "ui/components";
import { ReactComponent as IconAddUser } from "assets/svg/add-user-icon.svg";
import { ReactComponent as IconLink } from "assets/svg/icon-link.svg";
import { clsxm } from "application/utils";
import { useEffect, useState } from "react";
import ProjectSharingGeneralAccess from "./ProjectSharingGeneralAccess";
import { Done } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useErrorHandler, useProjectAlias } from "ui/hooks";
import ProjectSharingPeopleWithAccess from "./ProjectSharingPeopleWithAccess";
import { ProjectSharingInput } from "./ProjectSharingInput";
import { useGetUsersWithRolesQuery } from "redux/Api/DataroomPermissionsApi";
import projectService from "services/project.service";
import { validate } from "email-validator";

interface ProjectSharingProps {
  className?: string;
  title?: string;
  standalone?: boolean;
  open?: boolean;
  projectId?: string;
  onClose?: () => void;
}

const ProjectSharing = ({
  className,
  title = "Sharing Options",
  standalone = false,
  open = false,
  projectId,
  onClose,
}: ProjectSharingProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const [isCopied, setIsCopied] = useState(false);
  const alias = useProjectAlias();
  const [users, setUsers] = useState<string[]>([]);
  const { data: usersWithAccess, refetch } = useGetUsersWithRolesQuery({
    resourceId: projectId ?? alias,
    type: "Project",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const onSave = async () => {
    setIsLoading(true);
    const contacts = {
      contacts: users.filter(validate).map((email) => ({ email })),
    };
    try {
      await projectService.shareProject(projectId ?? alias, contacts);
      refetch();
      enqueueSnackbar("Saved!", {
        title: "Successfully updated",
        variant: "success",
      });
      setUsers([]);
    } catch (error) {
      handleError(
        error,
        "It was not possible to update the users. Please try again later."
      );
    }
    setIsLoading(false);
  };

  return (
    <div className={clsxm(className)}>
      {!standalone && (
        <>
          <CurrentUserAvatar className="w-8 h-8 hidden md:block" />
          <Button
            variant="outlined"
            size="small"
            className="mr-2 w-full"
            onClick={handleOpen}
            endIcon={<IconAddUser className="w-4 h-4" />}
          >
            Share
          </Button>
        </>
      )}
      <Modal
        title={title}
        open={isOpen || open}
        onClose={handleClose}
        bodyProps={{
          className: "max-w-[1200px] md:w-[600px]",
        }}
      >
        <div className="w-full max-w-[588px]">
          <ProjectSharingInput
            value={users}
            onChange={(value) => {
              setUsers(value);
            }}
          />
        </div>
        {usersWithAccess?.items && usersWithAccess?.items?.length > 0 && (
          <ProjectSharingPeopleWithAccess
            className="mt-4"
            data={usersWithAccess?.items}
            projectId={projectId}
          />
        )}
        <ProjectSharingGeneralAccess className="mt-4" />
        <div className="flex flex-col md:flex-row gap-2 mt-8">
          <Button
            disabled={isCopied}
            className="w-full order-2 md:order-1"
            variant="outlined"
            size="large"
            endIcon={
              isCopied ? <Done className="w-4" /> : <IconLink className="w-4" />
            }
            onClick={async () => {
              if (navigator?.clipboard && window?.location?.href) {
                await navigator.clipboard.writeText(window.location.href);
                enqueueSnackbar("The link has been copied", {
                  title: "Copied!",
                  variant: "success",
                });
                setIsCopied(true);
              }
            }}
          >
            {isCopied ? "Copied" : "Copy link"}
          </Button>
          <Button
            className="w-full order-1 md:order-2"
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              isLoading ? (
                <CircularProgress size={16} />
              ) : (
                <Done className="w-4" />
              )
            }
            disabled={!users.length || !users.some(validate) || isLoading}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectSharing;
