import { ChevronLeft } from "@mui/icons-material";
import { Avatar, IconButton, Tab, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { getNameInitials } from "application/utils";
import { Fragment, Suspense, useState } from "react";
import { generatePath, Link, Outlet } from "react-router-dom";
import { DealPermissions } from "types/deal";
import { IProject } from "types/project.service";
import { Loading } from "ui/components";
import { BetaTag } from "ui/components/BetaTag";
import { LinkTabs } from "ui/components/LinkTabs";
import {
  useCurrentPath,
  useCurrentProject,
  useGroupValidation,
  useProjectAlias,
  useSyndicationLayout,
} from "ui/hooks";
import * as paths from "ui/Router/paths";
import ProjectSharing from "./ProjectSharing";

interface TabItemDef {
  label: string;
  to: string;
  permissionsRequired: Array<keyof DealPermissions>;
  subPaths: string[];
  validate?: (project?: IProject) => boolean;
  featureFlag?: string;
  hiddenToSyndication?: boolean;
  beta?: boolean;
}

const items: TabItemDef[] = [
  {
    label: "Data Room",
    to: paths.projectDataRoomVDR,
    permissionsRequired: [],
    subPaths: [],
  },
  {
    label: "Assistant",
    to: paths.projectAssistant,
    permissionsRequired: [],
    subPaths: [],
    beta: true,
  },
  {
    label: "Reports",
    to: paths.projectReports,
    permissionsRequired: [],
    subPaths: [],
    beta: true,
  },
  {
    label: "Settings",
    to: paths.projectSettings,
    permissionsRequired: [],
    subPaths: [],
  },
];

export const ProjectPageWrapper = () => {
  const alias = useProjectAlias();
  const { data } = useCurrentProject();
  const currentPath = useCurrentPath();
  // const { permissions } = useProjectPermissions();
  const { isSyndicate } = useSyndicationLayout();
  const { isDeveloper, isAdmin } = useGroupValidation();
  const [tabsHeight, setTabsHeight] = useState<null | number>(null);

  const currentTabValue =
    items.find(({ to, subPaths }) =>
      [to, ...subPaths].includes(currentPath || "")
    )?.to || items[0]?.to;

  if (!data) {
    return <Loading full />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <div
        ref={(ref) => setTabsHeight(ref?.clientHeight || null)}
        className="w-full border-b border-solid border-gray-200"
      >
        <div className="flex md:flex-row flex-col m9-container !py-0">
          <div className="flex md:items-center py-4">
            <div>
              <Link to={"/" + paths.projectList}>
                <IconButton className="border-2 border-solid border-gray-200">
                  <ChevronLeft />
                </IconButton>
              </Link>
            </div>
            <div className="flex ml-4">
              <Avatar src={data.imageUrl} variant="rounded">
                {getNameInitials(data.name)}
              </Avatar>
              <div className="flex flex-col ml-2">
                <Typography variant="body2" className="font-bold text-gray-500">
                  {data.name}
                </Typography>
                <Typography
                  variant="caption2"
                  className="font-bold text-gray-400 mt-1"
                >
                  By {data.ownerName}
                </Typography>
              </div>
            </div>
          </div>
          <LinkTabs wrapperClassName="mx-auto" value={currentTabValue}>
            {items.map(
              (
                {
                  label,
                  permissionsRequired,
                  to,
                  validate,
                  featureFlag,
                  hiddenToSyndication,
                  beta,
                },
                index
              ) => {
                const hasPermissions = true;
                // isEmpty(permissionsRequired) ||
                // permissionsRequired.every((key) => permissions?.[key]);

                if (isSyndicate && hiddenToSyndication) {
                  return null;
                }

                if (validate && !validate(data)) {
                  return null;
                }

                if (!hasPermissions) {
                  return null;
                }

                if (featureFlag) {
                  return null;
                }

                return (
                  <Tab
                    key={index}
                    value={to}
                    className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
                    component={Link}
                    to={generatePath("/" + to, { alias })}
                    label={
                      <div className="flex items-center justify-center space-x-1">
                        <div>{label}</div>
                        {beta && <BetaTag />}
                      </div>
                    }
                  />
                );
              }
            )}
          </LinkTabs>
          {(isDeveloper || isAdmin) && (
            <ProjectSharing
              title={`Share '${data.name}'`}
              className="flex gap-2 items-center md:self-center my-5 md:my-0"
            />
          )}
        </div>
      </div>

      {tabsHeight && (
        <div
          className="flex flex-col grow-0 overflow-hidden"
          style={{ height: `calc(100% - ${tabsHeight}px)` }}
        >
          <Suspense fallback={<Fragment />}>
            <Outlet />
          </Suspense>
        </div>
      )}
    </Box>
  );
};
