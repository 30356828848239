import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreateRoleMutation } from "redux/Api/DataroomPermissionsApi";
import { vestResolver } from "@hookform/resolvers/vest";
import { validationSuite } from "../AddRole/addRoleValidation";
import { Loading } from "ui/components";
import { MenuItem, TextField, useTheme } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import { ReactComponent as IconPlusSquareSVG } from "assets/svg/plus-square.svg";
import { useErrorHandler } from "ui/hooks";
import { ResourceType } from "types/resource.service";

interface CreateNewRoleProps {
  roleNames?: string[];
  resourceId: string;
  type: ResourceType;
}

const CreateNewRole = ({ roleNames, resourceId, type }: CreateNewRoleProps) => {
  const [openAddNewRole, setOpenAddNewRole] = useState(false);
  const [createRoleMutation, { isLoading }] = useCreateRoleMutation();
  const { handleError } = useErrorHandler();
  const { customColors } = useTheme();

  const {
    register,
    handleSubmit,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: vestResolver(validationSuite(roleNames)),
  });

  const clearInput = () => {
    setOpenAddNewRole(false);
    clearErrors("role");
    resetField("role");
  };

  const createRoleHandler = async (roleName: string) => {
    try {
      if (resourceId) {
        await createRoleMutation({
          resourceId,
          type,
          roleName,
        }).unwrap();
      }
    } catch (error) {
      handleError(error, "Failed to create new role");
    }
  };

  const onSubmit: SubmitHandler<{ role?: string }> = (data) => {
    if (data.role) {
      createRoleHandler(data.role.trim());
      clearInput();
    }
  };

  return (
    <>
      {isLoading && <Loading size={32} className="my-[16px]" />}
      {openAddNewRole && !isLoading && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="px-4 py-2 flex gap-2"
        >
          <TextField
            {...register("role")}
            InputProps={{
              className: "text-gray-500",
            }}
            inputProps={{
              maxLength: 25,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-error fieldset": {
                  borderWidth: "1px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: customColors.gray[300],
                  borderWidth: "1px",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: customColors.gray[300],
                borderWidth: "1px",
              },
            }}
            FormHelperTextProps={{
              className: "text-[12px]",
            }}
            size="small"
            autoFocus
            placeholder="Role name"
            error={!!errors.role}
            helperText={errors.role?.message}
            onKeyDown={(e) => e.stopPropagation()}
            onFocus={(e) => {
              e.stopPropagation();
            }}
          />
          <div
            onClick={clearInput}
            className="border-2 border-gray-200 w-8 h-8 mt-1 rounded-full flex flex-shrink-0 items-center justify-center cursor-pointer hover:border-gray-300 border-solid transition-colors text-gray-400"
          >
            <Close className="p-1" />
          </div>
          <button
            type="submit"
            className="border-2 border-primary-400 w-8 h-8 mt-1 rounded-full flex items-center justify-center cursor-pointer hover:border-primary border-solid transition-colors bg-primary hover:bg-primary-400"
          >
            <Done className="p-1 text-white" />
          </button>
        </form>
      )}
      {!openAddNewRole && !isLoading && (
        <MenuItem onClick={() => setOpenAddNewRole(true)}>
          <IconPlusSquareSVG className="mr-2" />
          Add new role
        </MenuItem>
      )}
    </>
  );
};

export default CreateNewRole;
