import AdapterDateFns from "@mui/lab/AdapterDateFns";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Auth } from "aws-amplify";
import enLocale from "date-fns/locale/en-US";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "ui/styles/globals.scss";
import reportWebVitals from "./reportWebVitals";
import { getSyndicationId } from "application/utils";
import { defaultBrandingColors } from "domain/common";
import { useEffect, useMemo, useState } from "react";
import { RootState } from "redux/rootReducer";
import store, { persistor } from "redux/store";
import { ISyndicationPartnerBrandingColors } from "types/syndication.service";
import AppRouter from "ui/Router/AppRouter";
import { useSyndicationConfig } from "ui/hooks/Syndication/useSyndicationConfig";
import "ui/styles/styles.scss";
import { NotificationSnackbar } from "./ui/components/NotificationSnackbar";
import { getTheme } from "./ui/theme/theme";
import { SWRConfig } from "swr";
import { config } from "config";
import "react-loading-skeleton/dist/skeleton.css";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";

if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

Auth.configure(config.aws);

const App = () => {
  const { getState } = store;
  const [isInitialized, setInitialized] = useState(false);
  const [hasLayoutError, setHasLayoutError] = useState<boolean>(false);
  const { data } = useSyndicationConfig(
    {
      revalidateIfStale: true,
      shouldRetryOnError: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      onError: () => setHasLayoutError(true),
    },
    true
  );

  useFeatureFlag();

  useEffect(() => {
    if (isInitialized) return;

    const syndicateId = getSyndicationId();

    if ((getState() as RootState)?.syndicate?.syndicateLayout) {
      setInitialized(true);
    }

    if (!syndicateId || data || hasLayoutError) return setInitialized(true);
    return;
  }, [data, hasLayoutError, getState, isInitialized]);

  const colors = useMemo<IBrandingColors>(() => {
    if (!isInitialized) return defaultBrandingColors;
    const state = getState() as RootState;
    const brandingColors: Partial<ISyndicationPartnerBrandingColors> =
      state?.syndicate?.syndicateLayout?.branding?.colors || {};

    return {
      mainColor: brandingColors?.primary || defaultBrandingColors.mainColor,
      mainColor0: brandingColors?.primary0 || defaultBrandingColors.mainColor0,
      mainColor100:
        brandingColors?.primary100 || defaultBrandingColors.mainColor100,
      mainColor200:
        brandingColors?.primary200 || defaultBrandingColors.mainColor200,
      mainColor300:
        brandingColors?.primary300 || defaultBrandingColors.mainColor300,
      mainColor400:
        brandingColors?.primary400 || defaultBrandingColors.mainColor400,
      mainColor600:
        brandingColors?.primary600 || defaultBrandingColors.mainColor600,
      textColor: brandingColors?.textColor || defaultBrandingColors.textColor,
    };
  }, [getState, isInitialized]);

  useEffect(() => {
    document.documentElement.setAttribute(
      "style",
      `
      --main-color: ${colors.mainColor};
      --main-color-0: ${colors.mainColor0};
      --main-color-100: ${colors.mainColor100};
      --main-color-200: ${colors.mainColor200};
      --main-color-300: ${colors.mainColor300};
      --main-color-400: ${colors.mainColor400};
      --main-color-600: ${colors.mainColor600};
      --text-color: ${colors.textColor};`
    );
  }, [colors]);

  if (!isInitialized) return null;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={getTheme(colors)}>
              <SnackbarProvider
                maxSnack={3}
                Components={{
                  success: NotificationSnackbar,
                  default: NotificationSnackbar,
                  error: NotificationSnackbar,
                  warning: NotificationSnackbar,
                  info: NotificationSnackbar,
                }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <SWRConfig
                  value={{
                    revalidateOnFocus: false,
                    shouldRetryOnError: true,
                    errorRetryCount: 3,
                    errorRetryInterval: 3000,
                  }}
                >
                  <CssBaseline />
                  <AppRouter hasLayoutError={hasLayoutError} />
                </SWRConfig>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
