import * as paths from "ui/Router/paths";
import { NavigationItemProps } from "ui/components";
import { CommonLinks } from "domain/common";
import { FeatureFlagId } from "domain/featureFlag";

export const getNavItems = (isAuthorized: boolean): NavigationItemProps[] => {
  if (isAuthorized) {
    return [
      {
        title: "Home",
        hiddenForSyndicate: true,
        hiddenInLargerScreen: true,
        featureFlag: FeatureFlagId.Deals,
        navItems: [
          {
            text: "Invest",
            to: "/" + paths.landingInvest,
          },
          {
            text: "Syndicate",
            to: "/" + paths.landingISyndicate,
          },
          {
            text: "AI",
            to: paths.landingAi,
          },
          {
            text: "Podcasts",
            to: "/" + paths.podcasts,
          },
          {
            text: "Blog",
            to: CommonLinks.Blog,
            external: true,
          },
        ],
      },
      {
        title: "Deals",
        featureFlag: FeatureFlagId.Deals,
        navItems: [
          {
            text: "All",
            to: "/" + paths.dealsAll,
          },
        ],
      },
      {
        title: "Projects",
        featureFlag: FeatureFlagId.Projects,
        navItems: [
          {
            text: "All",
            to: "/" + paths.projectList,
          },
        ],
      },
      {
        title: "Your Portfolio",
        featureFlag: FeatureFlagId.Deals,
        navItems: [
          {
            text: "Overview",
            to: "/" + paths.portfolioPath,
          },
        ],
      },
      {
        title: "Notifications",
        navItems: [
          {
            text: "All",
            to: "/" + paths.notificationsPath,
          },
        ],
      },
      {
        title: "Settings",
        navItems: [
          {
            text: "Preferences",
            to: "/" + paths.preferencesPath,
          },
          {
            text: "Invite your contacts",
            to: "/" + paths.inviteContacts,
          },
        ],
      },
    ];
  }

  return [
    // {
    //   title: "About us",
    //   navItems: [
    //     {
    //       text: "How it works",
    //     },
    //     {
    //       text: "About us",
    //     },
    //     {
    //       text: "Contact",
    //     },
    //   ],
    // },
    {
      title: "Home",
      hiddenInLargerScreen: true,
      hiddenForSyndicate: true,
      navItems: [
        {
          text: "Invest",
          to: paths.landingInvest,
        },
        {
          text: "Syndicate",
          to: paths.landingISyndicate,
        },
        {
          text: "AI",
          to: paths.landingAi,
        },
        {
          text: "Podcasts",
          to: paths.podcasts,
        },
        {
          text: "Blog",
          to: CommonLinks.Blog,
          external: true,
        },
      ],
    },
    {
      title: "Start now",
      navItems: [
        {
          text: "Sign up",
          to: paths.signUpPath,
        },
        {
          text: "Login",
          to: paths.signInPath,
        },
      ],
    },
  ];
};
