/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from "aws-amplify";
import clsx, { ClassValue } from "clsx";
import { config } from "config";
import {
  formatDuration as dataFnsFormatDuration,
  differenceInMilliseconds,
  format,
  fromUnixTime,
  getYear,
  intervalToDuration,
  isDate,
} from "date-fns";
import { AuthGroupEnum } from "domain/authorization";
import { validate } from "email-validator";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { isArray, isEqual, isNil, isString } from "lodash";
import startCase from "lodash/startCase";
import { twMerge } from "tailwind-merge";
import { ISyndicationPartnerUserGroupsResponse } from "types/admin.service";
import { enforce, test } from "vest";
import { FeedbackError, TimeoutError } from "./errors";
import {
  passwordMaxLength,
  passwordMinLength,
  passwordSpecialCharacterRegex,
} from "./validation";

export const svgToPngDataUrl = async (svgString: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = svgString;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const pngDataUrl = canvas.toDataURL("image/png");
        resolve(pngDataUrl);
      }
      reject("The context could be created");
    };
    img.onerror = function (...props) {
      reject(new Error("Failed to load image and convert SVG to PNG "));
    };
  });
};

export function delay<T>(time: number) {
  return function (result?: T) {
    return new Promise((resolve) => setTimeout(() => resolve(result), time));
  };
}

export function delayAsync(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const parseCurrencyString = (value: string) =>
  +value.replace(/\D+/g, "");

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const convertUnixToDate = (value: string | number) =>
  fromUnixTime(Number(value));

const convertTimestampToDate = (value: string) => new Date(value);

export interface FormatDateOptions {
  unix?: boolean;
  long?: boolean;
}

export const formatDate = (
  value: string | number,
  { unix, long }: FormatDateOptions = { unix: false }
) => {
  const valueAsDate = unix
    ? convertUnixToDate(value)
    : convertTimestampToDate(value as string);

  return format(valueAsDate, long ? "MMM, dd yyyy HH:mm" : "MMM, dd yyyy");
};

// { minutes: 30, seconds: 7 }

const zeroPad = (num: number) => String(num).padStart(2, "0");

export const formatDuration = (durationInSeconds: number | string) => {
  try {
    const duration = intervalToDuration({
      start: 0,
      end: Number(durationInSeconds) * 1000,
    });

    return dataFnsFormatDuration(duration, {
      format: ["hours", "minutes", "seconds"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => zeroPad(count),
      },
    });
  } catch (e) {
    return "-:-:-";
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!isValidPhoneNumber(phoneNumber)) {
    return "Invalid phone number";
  }

  const parsed = parsePhoneNumber(phoneNumber);

  return parsed.formatInternational();
};

export interface FormatCurrencyParams extends Intl.NumberFormatOptions {
  compact: boolean;
}

export const formatNumber = (value: number, compact = false) =>
  new Intl.NumberFormat("en-US", {
    compactDisplay: "short",
    notation: compact ? "compact" : "standard",
  }).format(value);

export const formatCurrency = (
  value: number,
  params: FormatCurrencyParams = { compact: false }
) => {
  const { compact, ...intlParams } = params;

  const formatter = Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
    maximumFractionDigits: compact ? 4 : 2,
    minimumFractionDigits: 0,
    compactDisplay: "short",
    notation: compact ? "compact" : "standard",
    ...intlParams,
  });

  if (compact) {
    const result = formatter.format(value);
    return result.slice(0, -1) + result.slice(-1).toLowerCase();
  }

  return formatter.format(value);
};

interface Response {
  status: number;
  data: {
    response: any[];
  };
}

export const getNameInitials = (fullName = "") =>
  fullName
    .split(" ")
    .map((namePart) => namePart[0] || "")
    .slice(0, 2)
    .join("")
    .toUpperCase() || "--";

export const getArrayLikeData = (response: Response) => {
  if (response.status !== 200) {
    return null;
  }

  const data = response?.data?.response;

  return data && Array.isArray(data) && data.length ? data : null;
};

export const valuesToOptions = (values: string[]) =>
  values.map((value) => ({ label: startCase(value), value }));

export const currencyFormat = (amount: number) => {
  return "$" + amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getDealListQuery = (idList: string[]): string =>
  "?" + idList.map((id) => "ids=" + id).join("&");

export interface AdaptObjectConfig {
  [key: string]: (value: any) => any;
}

/**
 *
 * It receives a source object and the config with the instructions of which keys the source object will need to be transformed to.
 * Each function in the config object will run in the respective key in the source object,
 * and the function return will be the new key in the new result object.
 *
 * @param formData Source object
 * @param config Object with the definitions of which/how the keys should be transformed
 * @returns The transformed object, that uses the config as method to transform their keys
 */

export const adaptObject = <T>(
  formData: Record<string, any>,
  config: AdaptObjectConfig
): T => {
  const keys = Object.keys(config);
  if (keys.length) {
    const merge = keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: config[key](formData[key]),
      }),
      {} as Partial<T>
    );
    return { ...formData, ...merge } as T;
  }

  return { ...formData } as T;
};

export async function copyTextToClipboard(text: string) {
  return await navigator.clipboard.writeText(text);
}

export function getTimestampOrValue(value: any) {
  if (value && isDate(value) && !isNaN(value)) {
    return value.valueOf();
  }
  return value;
}

// TODO refactor function with same name to use this one as import
export const getNameCombined = (firstName?: string, lastName?: string) =>
  `${firstName || ""} ${lastName || ""}`;

export const getInitials = (fullName: string) =>
  fullName
    .split(" ")
    .map((str) => str[0])
    .join("")
    .substring(0, 2)
    .toUpperCase();

export const downloadFileByUrl = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (file: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  downloadFileByUrl(url, fileName);
};

export const getDecodedUrl = (url: string) => {
  if (!url) return url;
  const removedPlusSign = url.replace("+", " ");

  return decodeURI(removedPlusSign);
};

export const isValidHttpUrl = (string: string) => {
  try {
    const url = new URL(string);

    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

export const getTypedTest =
  <T extends object>() =>
  (title: NestedKeyOf<T>, errorMessage: string, enforceCallback: () => void) =>
    test(title, errorMessage, enforceCallback);

export const getMainGroup = (roles: AuthGroupEnum[]) => {
  const mostRelevantSorted: AuthGroupEnum[] = [
    AuthGroupEnum.Admin,
    AuthGroupEnum.FundManager,
    AuthGroupEnum.Investor,
    AuthGroupEnum.FeaturedInvestor,
    AuthGroupEnum.User,
  ];

  return mostRelevantSorted.find((role) => roles.indexOf(role) > -1);
};

const getListener = (str: string) => (e: any) => {
  e.clipboardData.setData("text/html", str);
  e.clipboardData.setData("text/plain", str);
  e.preventDefault();
};

export const copyToClip = (str: string) => {
  const listener = getListener(str);

  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

export const getCurrencySymbol = (currency: string): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    currency: currency,
    style: "currency",
    maximumFractionDigits: 0,
  });

  return formatter.format(1).replace(/\d/, "");
};

export const clearBrowserCache = async () => {
  if ("caches" in window) {
    try {
      const keys = await caches.keys();

      keys.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    } catch (e) {
      console.error(e);
    }
  }
};

export const getSyndicationId = () => {
  const { hostname } = window.location;

  const sanitizedHostname = hostname
    .replace(`${config.env}.`, "")
    .replace(".com", "");

  return sanitizedHostname.includes(".") && sanitizedHostname.split(".")[0];
};

export function createTypedFormData<T>(data: T): FormData {
  const formData = new FormData();

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];

      if (!isNil(value)) {
        formData.append(key, value as any);
      }
    }
  }

  return formData;
}

// Update GOOGLE TAG data
export const updateGtagWithData = (updatedConsentMode: {
  functionality_storage: string;
  security_storage: string;
  analytics_storage: string;
}) => {
  if ((window as any)?.gtag) {
    (window as any)?.gtag("consent", "update", updatedConsentMode);
  }
};

export const getMainRoleBySyndicationGroups = (
  syndicationGroups?: ISyndicationPartnerUserGroupsResponse
) => {
  if (!syndicationGroups) return null;

  const { isAdmin, isInvestor, isUser } = syndicationGroups;

  if (isAdmin) {
    return "Admin";
  }

  if (isUser && isInvestor) {
    return "Investor";
  }

  if (isUser) {
    return "User";
  }

  return "No role";
};

export const getEnvironmentUrl = (subdomain?: string): string => {
  const defaultURL = config.siteUrl || "dev";

  if (!subdomain) return defaultURL;

  const { protocol, host } = new URL(defaultURL);
  return [protocol, "//", subdomain, ".", host].join("");
};

export const poolRequest = async <T>(
  fn: () => Promise<T>,
  validator: (value: T) => boolean,
  intervalInMs: number,
  timeoutInMs: number | null
): Promise<T | null> => {
  const start = new Date();
  const run = true;

  while (run) {
    const difference = differenceInMilliseconds(new Date(), start);
    const overdue = timeoutInMs && difference > timeoutInMs;

    if (overdue) {
      throw new TimeoutError("The pooling took more time than expected");
    }

    const result = await fn();
    const isValid = validator(result);

    if (!isValid) {
      await delayAsync(intervalInMs);
      continue;
    }

    return result;
  }

  return null;
};

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  (outer.parentNode as HTMLDivElement).removeChild(outer);

  return scrollbarWidth;
}

export const getRichTextContent = (text: string, type = ""): string => {
  const regex = new RegExp(`\`\`\`${type}\\s*\\n*([\\s\\S\\n]*?)\`\`\``, "gm");
  const match = regex.exec(text);
  return match ? match[1].trim() : text;
};

export const getAssistantJSONValue = <T>(
  text: string,
  ignoreError?: boolean
): T | undefined => {
  try {
    const richText = getRichTextContent(text, "json");

    return JSON.parse(richText);
  } catch (e) {
    if (ignoreError) return;

    throw new FeedbackError(
      "The assistant didn't return the expected field value. Please try again later."
    );
  }
};

export const refreshTokenAndGetNewToken = async () => {
  const session = await Auth.currentSession();
  const refreshToken = session.getRefreshToken();

  return Auth.currentAuthenticatedUser().then((res) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration() * 1000;
        const currentTimeSeconds = Math.round(+new Date());
        if (idTokenExpire < currentTimeSeconds) {
          res.refreshSession(refreshToken, (err: Error, session: any) => {
            if (err) {
              reject(err);
            } else {
              resolve("Bearer " + session.getIdToken().getJwtToken());
            }
          });
        } else {
          resolve("Bearer " + session.getIdToken().getJwtToken());
        }
      });
    });
  });
};

export const includeKeys = (obj: Record<string, any>, keys: string[]) => {
  const objKeys = Object.keys(obj);
  return keys.every((item) => objKeys.indexOf(item) > -1);
};

// Merge classes with tailwind-merge with clsx full feature
export const clsxm = (...classes: ClassValue[]) => twMerge(clsx(...classes));

export const getCurrentYear = () => {
  return getYear(new Date());
};

export const getVestValidateEmail = (emailAddress: string) => () => {
  enforce(validate(emailAddress)).isTruthy();
};

export const safeTrim = (value?: string): string => (value ? value.trim() : "");

export const isArrayOfStrings = (value: unknown) =>
  isArray(value) && value.every(isString);

export const isArraysOfStringsEqual = (a: string[], b: string[]): boolean =>
  isEqual([...a].sort(), [...b].sort());

export const cleanAssistantMessage = (message: string): string => {
  return message.replace(new RegExp("【[^】]*†[^】]*】", "g"), "");
};

export const applyPasswordValidation = (
  fieldName: string,
  fieldValue: string
) => {
  test(fieldName, "Password is required", () => {
    enforce(fieldValue).isNotEmpty();
  });

  test(fieldName, `Minimum ${passwordMinLength} characters required`, () => {
    enforce(fieldValue).longerThanOrEquals(passwordMinLength);
  });

  test(fieldName, `Maximum ${passwordMaxLength} characters exceeded`, () => {
    enforce(fieldValue).shorterThanOrEquals(passwordMaxLength);
  });

  test(fieldName, "Password must contain a lower case letter", () => {
    enforce(fieldValue).matches(/[a-z]/);
  });

  test(fieldName, "Password must contain an upper case letter", () => {
    enforce(fieldValue).matches(/[A-Z]/);
  });

  test(fieldName, "Password must contain a digit", () => {
    enforce(fieldValue).matches(/[\d]/);
  });

  test(fieldName, "Password must contain a special character", () => {
    enforce(fieldValue).matches(passwordSpecialCharacterRegex);
  });

  test(fieldName, "Whitespace isn't allowed in password", () => {
    enforce(fieldValue).notMatches(/[\s]/);
  });
};
