import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import clsx from "clsx";
import {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  ReactElement,
  useMemo,
} from "react";

interface SearchInputProps extends StandardTextFieldProps {
  buttonType?: ButtonHTMLAttributes<any>["type"];
  iconButtonClasses?: string;
  customIcon?: ReactElement;
  customButton?: ReactElement;
  loading?: boolean;
  onButtonClick?: MouseEventHandler;
}

export const SearchInput: FC<SearchInputProps> = ({
  buttonType = "submit",
  size = "medium",
  onChange,
  onBlur,
  name,
  iconButtonClasses,
  InputProps,
  customIcon,
  customButton,
  loading,
  onButtonClick,
  disabled,
  ...props
}) => {
  const icon = useMemo(() => {
    if (loading) {
      return <CircularProgress size={16} className="mx-auto my-auto" />;
    }

    return customIcon || <Search />;
  }, [customIcon, loading]);

  return (
    <div className="relative w-full text-inherit">
      <TextField
        className={clsx(
          "w-full disabled:border disabled:border-solid disabled:border-gray-300"
        )}
        size={size}
        {...props}
        InputProps={{
          readOnly: disabled,
          endAdornment: customButton || (
            <IconButton
              onClick={onButtonClick}
              className={clsx(
                {
                  "h-8 w-8": size === "small",
                  "h-10 w-10": size === "medium",
                },
                "-my-2 relative bg-gray-200",
                iconButtonClasses
              )}
              type={buttonType}
              disabled={loading || disabled}
            >
              {icon}
            </IconButton>
          ),
        }}
      />
    </div>
  );
};
