import { isUndefined } from "lodash";
import projectService from "services/project.service";
import useSWR from "swr";
import { useProjectAlias } from "./useProjectAlias";

export const useProjectPermissions = (overwriteId?: string) => {
  const alias = useProjectAlias();
  const id = overwriteId || alias;

  const {
    data: permissions,
    error,
    ...rest
  } = useSWR(id ? `project/${id}/checkpermissions` : null, () =>
    projectService.getProjectPermissions(id)
  );

  const loading = [permissions, error].every(isUndefined);

  const dealView = permissions?.view;
  const dealAccess = dealView && permissions?.fundAccess;
  const dealEdit = dealView && dealAccess && permissions?.edit;

  return {
    permissions,
    dealEdit,
    dealView,
    dealAccess,
    loading,
    error,
    ...rest,
  };
};
