import { produce } from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useAddRoleToUserMutation,
  useGetRolesQuery,
  useRemoveRoleFromUserMutation,
} from "redux/Api/DataroomPermissionsApi";
import { ResourceType } from "types/resource.service";
import { useErrorHandler } from "ui/hooks";
import {
  DataRoomPermissionRoles,
  UserWithRoles,
} from "ui/pages/DealDataRoom/DealDataRoom.types";
import { compareRoles } from "../DataRoomUserPermissions.utils";

interface UseManageRolesProps {
  type: ResourceType;
  user: UserWithRoles;
  resourceId: string;
}

export const useManageRoles = ({
  resourceId,
  type,
  user: { roles: existent, userId },
}: UseManageRolesProps) => {
  const { data: available, isLoading: isAvailableLoading } = useGetRolesQuery({
    type,
    resourceId,
  });
  const [roles, setRoles] = useState<DataRoomPermissionRoles>([]);
  const [addRoleMutation, { isLoading: addMutationLoading }] =
    useAddRoleToUserMutation();
  const [removeRoleMutation, { isLoading: removeMutationLoading }] =
    useRemoveRoleFromUserMutation();
  const { handleError } = useErrorHandler();

  useEffect(() => {
    if (existent) {
      setRoles(existent);
    }
  }, [existent]);

  const rolesAvailable = available?.filter(
    (role) => !roles.map(({ roleId }) => roleId).includes(role.roleId)
  );

  const toggle = useCallback<(id: string) => void>(
    (id) => {
      setRoles(
        produce((draft) => {
          const roleFound = draft.find(({ roleId }) => roleId === id);
          if (roleFound) {
            draft.splice(draft.indexOf(roleFound), 1);
          } else {
            const role = available?.find(({ roleId }) => roleId === id);
            if (role) {
              draft.push(role);
            }
          }
        })
      );
    },
    [available]
  );

  const reset = useCallback(() => {
    setRoles(existent);
  }, [existent]);

  const isLoading = [
    !resourceId,
    isAvailableLoading,
    addMutationLoading,
    removeMutationLoading,
  ].some(Boolean);

  const roleNames = useMemo(() => {
    return available?.map(({ roleName }) => roleName.toLowerCase()) || [];
  }, [available]);

  const [added, removed] = useMemo(() => {
    return [compareRoles(roles, existent), compareRoles(existent, roles)];
  }, [roles, existent]);

  const isSaveDisabled = added.length === 0 && removed.length === 0;

  const save = useCallback(async () => {
    try {
      if (added.length) {
        await Promise.all(
          added.map((roleId) =>
            addRoleMutation({
              resourceId,
              roleId,
              usersIds: [userId],
              type,
            }).unwrap()
          )
        );
      }
      if (removed.length) {
        await Promise.all(
          removed.map((roleId) =>
            removeRoleMutation({
              resourceId,
              roleId,
              userId,
              type,
            }).unwrap()
          )
        );
      }
    } catch (error) {
      handleError(error, "Failed to save changes");
    }
  }, [
    added,
    removed,
    addRoleMutation,
    resourceId,
    userId,
    type,
    removeRoleMutation,
    handleError,
  ]);

  return {
    roles,
    rolesAvailable,
    toggle,
    reset,
    isLoading,
    roleNames,
    save,
    isSaveDisabled,
  };
};
