import { Tooltip, Typography } from "@mui/material";
import { clsxm } from "application/utils";
import { Checkbox } from "ui/components";
import { ReactComponent as RadioButtonIconChecked } from "assets/svg/radiobutton-checked.svg";
import { ReactComponent as RadioButtonIconUnChecked } from "assets/svg/radiobutton-unchecked.svg";
import { useState } from "react";

interface ProjectSharingGeneralAccessProps {
  className?: string;
  checkBoxWrapperClassName?: string;
  title?: string;
  data?: CheckBox[];
  onChange?: (index: number) => void;
  value?: number;
}

interface CheckBox {
  id: number;
  title: string;
  subtitle: string;
  disabled?: boolean;
}

const defaultData: CheckBox[] = [
  {
    id: 1,
    title: "Everyone who has a link",
    subtitle: "User don’t need to create account to see shared file",
    disabled: true,
  },
  {
    id: 2,
    title: "Only registered users",
    subtitle: "User need to have an Emerge9 account to access shared file",
  },
];

const ProjectSharingGeneralAccess = ({
  className,
  checkBoxWrapperClassName,
  title = "General access",
  data = defaultData,
  value = 1,
  onChange,
}: ProjectSharingGeneralAccessProps) => {
  const [checked, setChecked] = useState(value);

  const handleChange = (index: number) => () => {
    setChecked(index);
    onChange?.(index);
  };

  return (
    <div className={clsxm(className)}>
      <Typography className="text-gray-300 text-xs" variant="hairline2">
        {title}
      </Typography>
      <div
        className={clsxm(
          "bg-gray-150 rounded-lg p-3 mt-3",
          "flex gap-2 flex-wrap",
          checkBoxWrapperClassName
        )}
      >
        {data?.map(({ id, title, subtitle, disabled }, index) => {
          return (
            <div
              key={id}
              className={clsxm(
                "flex flex-col gap-1 border border-solid border-gray-200 rounded-lg p-3 w-[calc(50%-4px)]",
                checked === index && "bg-white"
              )}
            >
              <div className="flex items-center">
                <Checkbox
                  className="rounded-full"
                  checked={checked === index}
                  onChange={handleChange(index)}
                  checkedIcon={<RadioButtonIconChecked />}
                  icon={<RadioButtonIconUnChecked />}
                  disabled={disabled}
                />
                <Tooltip title={title}>
                  <Typography
                    variant="h6"
                    className={clsxm(
                      "font-sans text-gray-500 text-sm overflow-hidden text-ellipsis whitespace-nowrap",
                      disabled && "text-gray-300"
                    )}
                  >
                    {title}
                  </Typography>
                </Tooltip>
              </div>
              <Typography
                variant="h6"
                className={clsxm(
                  "font-sans text-gray-500 text-xs",
                  disabled && "text-gray-300"
                )}
              >
                {subtitle}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectSharingGeneralAccess;
