import { useMemo } from "react";
import { useFeatureFlag } from "../useFeatureFlag";

export enum EnabledFeaturesEnum {
  DealsAndProjects = "DealsAndProjects",
  ProjectsOnly = "ProjectsOnly",
  DealsOnly = "DealsOnly",
}

export type EnabledFeaturesState =
  | EnabledFeaturesEnum.DealsAndProjects
  | EnabledFeaturesEnum.ProjectsOnly
  | EnabledFeaturesEnum.DealsOnly
  | undefined;

export interface EnabledFeatures {
  projects: boolean;
  deals: boolean;
}

export const useEnabledFeatures = () => {
  const { flags } = useFeatureFlag();

  const enabledFeatures: EnabledFeatures = useMemo(
    () => ({
      projects: Boolean(flags?.projects?.isEnabled),
      deals: Boolean(flags?.deals?.isEnabled),
    }),
    [flags]
  );

  const enabledFeaturesState = (
    features: EnabledFeatures
  ): EnabledFeaturesState => {
    if (features.projects && features.deals) {
      return EnabledFeaturesEnum.DealsAndProjects;
    } else if (features.projects) {
      return EnabledFeaturesEnum.ProjectsOnly;
    } else if (features.deals) {
      return EnabledFeaturesEnum.DealsOnly;
    }
  };

  const state = enabledFeaturesState(enabledFeatures);

  return useMemo(() => {
    return {
      state,
      isProjectsOnly: state === EnabledFeaturesEnum.ProjectsOnly,
      isDealsOnly: state === EnabledFeaturesEnum.DealsOnly,
      isDealsAndProjects: state === EnabledFeaturesEnum.DealsAndProjects,
      isDealsOn: enabledFeatures.deals,
    };
  }, [state, enabledFeatures]);
};
