import {
  AutoAwesome,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, IconButton, Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { cleanAssistantMessage, getRichTextContent } from "application/utils";
import { ReactComponent as ArrowSend } from "assets/svg/arrow-send.svg";
import clsx from "clsx";
import {
  AssistantAttachableField,
  AssistantThreadMessageRole,
  DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE,
  DealFieldInfoMap,
  FieldAssistantType,
} from "domain/assistant";
import { startCase } from "lodash";
import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dealAssistantService from "services/dealAssistant.service";
import useSWR from "swr";
import { IAssistantMessage } from "types/assistant.service";
import { useAssistantStream, useErrorHandler } from "ui/hooks";
import { MessageLoader } from "../AssistantChat/AssistantChat.components";
import { BetaTag } from "../BetaTag";
import { CopyButton } from "../Button";
import { MarkdownInputViewer } from "../MarkdownInput";
import { SearchInput } from "../SearchInput";
import { useAssistantFloatingChatContext } from "./AssistantFloatingChat.context";

interface AssistantFloatingChatProps {
  dealId: string;
  fieldId: string;
  type: FieldAssistantType;
}

interface IMessage extends Omit<IAssistantMessage, "content"> {
  content: string | ReactElement;
  textToCopy?: string;
  error?: boolean;
}

export const AssistantFloatingChat: FC<AssistantFloatingChatProps> = ({
  dealId,
  fieldId,
  type,
}) => {
  const [isMinimized, setMinimized] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState<string>();
  const { handleError } = useErrorHandler();
  const { closeChat } = useAssistantFloatingChatContext();
  const { readStream, resetValue, streamMessages, isStreaming } =
    useAssistantStream(dealId);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: { message: "" },
  });

  const { data, mutate } = useSWR(
    threadId && `assistant/thread/request/${threadId}/messages`,
    () => {
      return dealAssistantService.getThreadById(dealId, threadId as string);
    },
    {
      refreshInterval: (value) => (value?.hasPendingRuns ? 5000 : 0),
    }
  );

  const onMessageAdd = useCallback(
    async (message: string) => {
      setLoading(true);

      try {
        if (!threadId)
          throw new FeedbackError(
            "Please, select a thread before adding messages"
          );

        const stream = await dealAssistantService.addThreadMessage(
          dealId,
          threadId,
          message
        );

        await readStream(stream, message);
        await mutate();
        resetValue();
      } catch (e) {
        handleError(e, "It was not possible to generate the thread.");
      } finally {
        setLoading(false);
      }
    },
    [dealId, handleError, mutate, readStream, resetValue, threadId]
  );

  const onInit = useCallback(async () => {
    setLoading(true);

    try {
      const newThread = await dealAssistantService.createAndRunFieldThread(
        dealId,
        fieldId
      );

      setThreadId(newThread.threadId);
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to generate the thread.");
    } finally {
      setLoading(false);
    }
  }, [dealId, fieldId, handleError, mutate]);

  const messages = useMemo<IMessage[]>(() => {
    if (!threadId) return [];

    const isLastMessageFromAssistant =
      [...(data?.messages || [])]?.pop()?.role ===
      AssistantThreadMessageRole.Assistant;

    const loadingMessages: IMessage[] =
      (isLoading || data?.hasPendingRuns) &&
      !isStreaming &&
      !isLastMessageFromAssistant
        ? [
            {
              content: <MessageLoader />,
              threadId,
              createdAt: new Date().toISOString(),
              role: AssistantThreadMessageRole.Assistant,
              assistantId: "",
              messageId: "",
              openAiId: "",
              updatedAt: "",
              textToCopy: "",
            },
          ]
        : [];

    const validMessages = [...(data?.messages || []), ...streamMessages];

    const parsedMessages: IMessage[] = validMessages.map((item) => {
      if (item.role === AssistantThreadMessageRole.User) {
        return item;
      }

      if (
        item.role === AssistantThreadMessageRole.Assistant &&
        item.content.includes("myfiles_browser")
      ) {
        return {
          ...item,
          content: DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE,
          error: true,
        };
      }

      switch (type) {
        case "markdown":
          return {
            ...item,
            textToCopy: cleanAssistantMessage(item.content),
            content: (
              <MarkdownInputViewer>
                {getRichTextContent(
                  cleanAssistantMessage(item.content),
                  "markdown"
                )}
              </MarkdownInputViewer>
            ),
          };
        default:
          return {
            ...item,
            textToCopy: cleanAssistantMessage(item.content),
            content: cleanAssistantMessage(item.content),
          };
      }
    });

    return [
      ...loadingMessages,
      ...[...parsedMessages].reverse(),
      {
        content: "Create field content",
        threadId,
        createdAt: new Date().toISOString(),
        role: AssistantThreadMessageRole.User,
        assistantId: "",
        messageId: "",
        openAiId: "",
        updatedAt: "",
      },
    ];
  }, [
    data?.hasPendingRuns,
    data?.messages,
    isLoading,
    isStreaming,
    streamMessages,
    threadId,
    type,
  ]);

  const hasError = useMemo(() => {
    return messages?.[0]?.error;
  }, [messages]);

  const options = useMemo(() => {
    if (hasError) {
      return ["Retry"];
    }

    return ["Elaborate", "Shorten", "Formalize"];
  }, [hasError]);

  return (
    <div
      className={clsx(
        "z-40 bg-white py-2 shadow-md fixed right-4 bottom-0 border border-solid border-gray-200 rounded-t-lg flex flex-col",
        isMinimized
          ? "cursor-pointer w-[319px]"
          : "h-[864px] max-w-[calc(100vw_-_32px)] w-[519px] max-h-[calc(100%_-_120px)]"
      )}
      onClick={isMinimized ? () => setMinimized(false) : undefined}
    >
      <div
        onClick={!isMinimized ? () => setMinimized(true) : undefined}
        className="px-4 flex flex-nowrap overflow-hidden w-full items-center shrink-0 cursor-pointer"
      >
        <div className="rounded-full border border-solid border-primary flex items-center text-primary bg-white py-1 px-2">
          <AutoAwesome className="mr-1 text-[1rem] text-inherit" />
          <Typography variant="caption2" className="text-primary">
            AI
          </Typography>
        </div>
        <Typography
          variant="caption"
          className="text-primary-600 ml-1 truncate font-bold"
        >
          -{" "}
          {DealFieldInfoMap[fieldId as AssistantAttachableField]?.label ||
            startCase(fieldId)}
        </Typography>
        <div className="ml-auto">
          <BetaTag />
        </div>
        <div className="flex space-x-4 items-center shrink-0 ml-2">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setMinimized(!isMinimized);
            }}
            className="text-primary h-8 w-8"
          >
            {isMinimized ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          {!isMinimized && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                closeChat();
              }}
              className="text-primary h-8 w-8"
            >
              <Close />
            </IconButton>
          )}
        </div>
      </div>

      {!isMinimized && [
        <div
          key={1}
          className="grow flex flex-col-reverse mt-4 px-4 pt-2 overflow-auto"
        >
          {!threadId && (
            <div className="mt-auto mx-auto mb-10 ">
              <LoadingButton
                startIcon={<AutoAwesome />}
                onClick={onInit}
                disabled={isLoading}
                className="h-8 px-3"
                variant="contained"
              >
                <Typography
                  variant="caption"
                  className="text-inherit font-bold"
                >
                  Create field content
                </Typography>
              </LoadingButton>
            </div>
          )}
          <div className="h-10 shrink-0 mb-auto w-full">&nbsp;</div>
          {messages.length > 0
            ? messages.map((message) => {
                const isUserMessage =
                  message.role === AssistantThreadMessageRole.User;

                return (
                  <div className="flex my-4">
                    <Typography
                      variant="body2"
                      className={clsx(
                        "p-3 rounded-lg shrink-0 w-fit max-w-[90%]",
                        isUserMessage
                          ? "ml-auto bg-primary-0 text-primary-600 rounded-br-none"
                          : "bg-gray-200 rounded-bl-none"
                      )}
                    >
                      {message.content}
                    </Typography>
                    {message.textToCopy && (
                      <div className="h-full pl-2 grow-0 shrink-0">
                        <CopyButton
                          textToCopy={message.textToCopy}
                          className="sticky top-1"
                        />
                      </div>
                    )}
                  </div>
                );
              })
            : null}
        </div>,
        <div key={2} className="relative items-center">
          <div className="pt-3 pb-2 flex items-center text-center justify-center w-full">
            <Typography
              variant="caption"
              className="flex items-center text-center justify-center text-gray-400"
            >
              Deal assistant won't always get it right.
            </Typography>
          </div>
          {threadId && (
            <div className="absolute -top-10 left-2 flex pl-4 py-2 space-x-1">
              {options.map((item) => (
                <Button
                  onClick={() => onMessageAdd(item)}
                  disabled={data?.hasPendingRuns || isLoading}
                  variant="outlined"
                  className="h-8 px-3 bg-white shadow-sm"
                  size="small"
                >
                  <Typography
                    variant="caption"
                    className="text-inherit font-bold"
                  >
                    {item}
                  </Typography>
                </Button>
              ))}
            </div>
          )}
          <div
            className={clsx(
              "w-full flex flex-nowrap space-x-2 border-0 border-t border-solid border-gray-200 px-4 pt-3",
              !threadId && "opacity-40"
            )}
          >
            <form
              onSubmit={handleSubmit(async ({ message }) => {
                await onMessageAdd(message);
                reset();
              })}
              className="w-full flex"
            >
              <Controller
                control={control}
                name="message"
                render={({ field }) => (
                  <SearchInput
                    disabled={data?.hasPendingRuns || !threadId || isLoading}
                    inputProps={field}
                    placeholder="Send a message"
                    buttonType="submit"
                    className="py-1 w-full"
                    customIcon={<ArrowSend />}
                  />
                )}
              />
            </form>
          </div>
        </div>,
      ]}
    </div>
  );
};
