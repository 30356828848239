import { useEnabledFeatures } from "./Syndication";
import * as paths from "ui/Router/paths";

export const usePaths = () => {
  const { isProjectsOnly } = useEnabledFeatures();

  return {
    mainRedirectPath: `/${isProjectsOnly ? paths.projectList : paths.dealsAll}`,
  };
};
